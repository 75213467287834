import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';

function Navbar() {

    //Toggle for navbar 
    const [menuOpen, setMenuOpen] = useState(false);
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };
    const closeMenu = () => {
        setMenuOpen(false);
    };

    //Scroll effect on links
    const [scrolled, setScrolled] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <header>
                <div className={`whole-nav ${scrolled ? 'whole-nav-scrolled' : ''}`}>
                    <div className='nav-title'>
                        <Link to="top" smooth={true} duration={900}><img src={require("../images/nola-text-title-image.png")} className='navbar-title' alt='Nolaqueenrell Title' width="268" height="30" /></Link>
                    </div>
                    <div className='nav-links'>
                        <nav className={`nav-container ${menuOpen ? 'show' : ''}`}>
                            <ul className='nav-link-items'>
                                <li><Link to="about-section" smooth={true} duration={500} className='nav-link' onClick={closeMenu}>About</Link></li>
                                <li><Link to="socials-section" smooth={true} duration={500} className='nav-link' onClick={closeMenu}>Socials</Link></li>
                                <li><Link to="clips-section" smooth={true} duration={500} className='nav-link' onClick={closeMenu}>Featured Clips</Link></li>
                                <li><Link to="affiliate-section" smooth={true} duration={500} className='nav-link' onClick={closeMenu}>Affiliations</Link></li>
                                <li><Link to="form-section" smooth={true} duration={500} className='nav-link' onClick={closeMenu}>Send a Message</Link></li>
                            </ul>
                        </nav>
                    </div>
                    <span id='icon-open' onClick={toggleMenu}><i className={`${menuOpen ? 'fa-solid fa-x' : 'fa-solid fa-bars'}`}></i></span>
                </div>
            </header>
        </>
    );
}
export default Navbar;