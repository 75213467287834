import React from 'react';
import { Link } from 'react-scroll';

function Footer() {
    return (
        <>
            <footer className='footer-container'>
                <hr className='footer-divider' />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <div className='footer-content'>
                    {/* <div className='footer-links'>
                        <h2>Info</h2>
                        <ul className='footer-list'>
                            <li><a href='https://www.twitch.tv/team/bigcreatxr' target='_blank' rel="noreferrer" className='team-link'>Big CreatXR</a></li>
                            <li>Business email: <a href='mailto:nolaqueenrell@gmail.com' className='footer-email'>nolaqueenrell@gmail.com</a></li>
                        </ul>
                    </div> */}

                    <div className='footer-title'>
                        <Link
                            to="top"
                            smooth={true}
                            duration={900}
                            offset={-70}
                            className='footer-scroll'>
                            <img src={require('../images/nola-text-title-image.png')} alt="Title" className='footer-title-name' width="419" height="66" />
                        </Link>
                    </div>

                </div>
                <br />
                <br />
                <br />
                <div className="footer-socials">
                    <div className='footer-social-img'>
                        <a href='https://www.instagram.com/nolaqueenrell/' target='_blank' rel='noreferrer'><img src={require('../images/instagram-logo.webp')} alt='Instagram Logo' className='social-image' width="39" height="39" /></a>
                    </div>
                    <div className='footer-social-img'>
                        <a href='https://twitter.com/nolaqueenrell' target='_blank' rel='noreferrer'><img src={require('../images/twitter-logo.webp')} alt='Twitter Logo' className='social-image' width="39" height="39" /></a>
                    </div>
                    <div className='footer-social-img'>
                        <a href='https://vm.tiktok.com/ZTdaMaqNA/' target='_blank' rel='noreferrer'><img src={require('../images/tiktok-logo.webp')} alt='Instagram Logo' className='social-image' width="39" height="39" /></a>
                    </div>
                    <div className='footer-social-img'>
                        <a href='mailto:nolaqueenrell@gmail.com' rel='noreferrer'><img src={require('../images/mail-logo.webp')} alt='Mail Logo' className='social-image' width="50" height="50" /></a>
                    </div>
                </div>
                <div className='footer-copyright'>
                    <p>© {new Date().getFullYear()} Nolaqueenrell. All rights reserved. | <a href="https://www.termsfeed.com/live/5fa31fed-ba5a-4e33-9b9e-9e20294108e2">Terms of Service</a></p>

                </div>
            </footer>
        </>
    );
}
export default Footer;